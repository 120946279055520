.star {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  animation: star 8s ease-in-out infinite, backgroundmove 16s linear infinite;
}
.star.star1 {
  animation-delay: 0s;
  background: url(../stars/star1.png);
}
.star.star2 {
  animation-delay: -1s;
  background: url(../stars/star2.png);
}
.star.star3 {
  animation-delay: -2s;
  background: url(../stars/star3.png);
}
.star.star4 {
  animation-delay: -3s;
  background: url(../stars/star4.png);
  background: url(./blacksky1.jpg);
}
.star.star5 {
  animation-delay: -4s;
  background: url(../stars/star5.png);
  background: url(./blacksky1.jpg);
}
.star.star6 {
  animation-delay: -5s;
  background: url(../stars/star6.png);
}
.star.star7 {
  animation-delay: -6s;
  background: url(../stars/star7.png);
}
.star.star8 {
  animation-delay: -8s;
  background: url(./blacksky1.jpg);
  background: url(../stars/star8.png);
}
@keyframes star {
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    opacity: 0;
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    opacity: 1;
  }
}

@keyframes backgroundmove {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
.scroll-down {
  border: 2px solid #fff;
  border-radius: 20px;
  bottom: 60px;
  height: 50px;
  left: 0;
  margin: 0 auto;
  position: fixed;
  right: 0;
  width: 30px;
}
.scroll-down::before {
  animation: scrollDownAnimation 2s infinite;
  background-color: #fff;
  border-radius: 100%;
  content: "";
  height: 6px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  top: 10px;
  width: 6px;
}
@-moz-keyframes scrollDownAnimation {
  0% {
    opacity: 0;
    transform: translate(0, 0);
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0;
    transform: translate(0, 20px);
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes scrollDownAnimation {
  0% {
    opacity: 0;
    transform: translate(0, 0);
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0;
    transform: translate(0, 20px);
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes scrollDownAnimation {
  0% {
    opacity: 0;
    transform: translate(0, 0);
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0;
    transform: translate(0, 20px);
  }
  100% {
    opacity: 0;
  }
}
@keyframes scrollDownAnimation {
  0% {
    opacity: 0;
    transform: translate(0, 0);
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0;
    transform: translate(0, 20px);
  }
  100% {
    opacity: 0;
  }
}
