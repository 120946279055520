#hero {
  background: rgb(20 0 38);
  height: 100%;
  width: 100%;
  font-family: "Signika Negative", sans-serif;
}
.hero {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 80px 0px;
}
.heroLeft,
.heroRight {
  width: 50%;
}
.heroLeft h1 {
  font-size: 45px;
  text-align: start;
  padding-left: 20%;
  color: rgb(255, 255, 255);
  margin-bottom: 30px;
}
.heroLeft p {
  font-size: 25px;
  text-align: start;
  padding-left: 20%;
  color: rgb(255, 255, 255);
}
.heroRight img {
  transform: translate(0%, 0%);
  animation: heroRight 3s infinite;
  width: 75%;
}
@keyframes heroRight {
  0% {
    transform: translate(0%, -2%);
  }
  50% {
    transform: translate(0%, 0%);
  }
  100% {
    transform: translate(0%, -2%);
  }
}
/* tablet */
@media (min-width: 768px) and (max-width: 1023px) {
}
/* Mobile */
@media (max-width: 767px) {
  .hero {
    flex-direction: column;
  }
  .heroLeft,
  .heroRight {
    margin-top: 30px;
    width: 100%;
  }
  .heroLeft h1 {
    padding-left: 5%;
    font-size: 25px;
  }
  .heroLeft p {
    padding-left: 5%;
    font-size: 18px;
    width: 80%;
    text-align: justify;
  }
}
