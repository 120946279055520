#footer {
  width: 100%;
  font-family: "Signika Negative", sans-serif;
}
.ps-0 {
  padding-left: 0px;
}
.footer {
  position: relative;
  width: 100%;
  height: auto;
  padding: 70px 0px 10px;
  background-color: #111;
  display: flex;
  flex-direction: column;
}
.footer .container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}
.footer .container .sec {
  margin-right: 30px;
}
.footer .container .sec.aboutus {
  width: 43%;
  text-align: start;
}
.footer .container h2 {
  position: relative;
  color: #fff;
  font-weight: 500;
  margin-bottom: 15px;
}
.footer .container h2:before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 50px;
  height: 2px;
  background: #f00;
}
.footer p {
  color: #999;
}
.sci {
  margin-top: 20px;
  display: flex;
}
.sci ul {
  /* padding-left: -30px; */
  /* padding-left: 0 !important; */

  padding-left: 100px;
}

.sci li {
  list-style: none;
}
.sci li a {
  display: inline-block;
  width: 40px;
  height: 40px;
  background: #222;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  border-radius: 4px;
}
.sci li a:hover {
  background: #f00;
}
.sci li a .fab {
  color: #fff;
  font-size: 20px;
}
.quicklinks {
  position: relative;
  width: 24%;
  text-align: start;
}
.quicklinks ul li {
  list-style: none;
}
.quicklinks ul li a {
  color: #999;
  text-decoration: none;
  margin-bottom: 10px;
  display: inline-block;
}
.quicklinks ul li a:hover {
  color: #fff;
}
.contact {
  width: calc(20% -60px);
  margin-right: 0 !important;
  text-align: start;
}
.contact .info {
  position: relative;
}
.contact .info li {
  display: flex;
  align-items: center;
}
.contact .info li span:nth-child(1) {
  color: #fff;
  font-size: 20px;
  margin-right: 10px;
}
.contact .info li span {
  color: #999;
  text-align: start;
}
.contact .info li a {
  color: #999;
  text-decoration: none;
}
.contact .info li a:hover {
  color: #fff;
}
.copyright {
  width: 100%;
  background-color: #181818;
  padding: 8px 0px;
  text-align: center;
  color: #999;
}
.copyright a {
  color: rgb(243, 243, 243);
  text-decoration: none;
}

i.fas {
  font-weight: 900;
  color: #ffffff;
  padding: 20px 20px 20px 0px;
  border-radius: 50%;
}

/* tablet */
@media (min-width: 768px) and (max-width: 1023px) {
  .footer .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .footer .container .sec {
    margin-right: 0px;
    margin-bottom: 40px;
  }
  .footer .container .sec ul {
    padding-left: 0px;
  }
  .footer .container .sec.aboutus,
  .quicklinks,
  .contact {
    width: 87%;
    align-self: center;
  }
  .copyright {
    /* padding: 8px 40px; */
  }
}
/* Mobile */
@media (max-width: 767px) {
  .footer .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .footer .container .sec {
    margin-right: 0px;
    margin-bottom: 40px;
  }
  .footer .container .sec ul {
    padding-left: 0px;
  }
  .footer .container .sec.aboutus,
  .quicklinks,
  .contact {
    width: 87%;
    align-self: center;
  }
  .copyright {
    /* padding: 8px 40px; */
  }
}
