.navbar {
  display: flex;
  background-color: rgb(20 0 38);
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: fixed;
  z-index: 1;
  box-shadow: 0px -3px 4px 2px #9d9393;
  height: 4rem;
  padding: 0px 2px;
}
.nav-logo {
  margin-left: 83px;
}
.nav-logo h1 {
  font-size: 1.8rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: rgb(3, 3, 3);
  text-transform: uppercase;
  font-family: "Audiowide", cursive;
}
.nav-logo span.cod {
  color: rgb(0 255 55);
}
.nav-logo span.e {
  color: #ffffff;
}
.nav-logo span.nrs {
  color: #01dcff;
}
.navList1 {
  position: relative;
  background: #ffffff00;
  font-size: 0;
  display: flex;
  flex-direction: row;
  column-gap: 15px;
  margin-right: 65px;
}
.navList1 a {
  cursor: pointer;
  font-size: 14px;
  text-transform: uppercase;
  color: rgb(0, 0, 0);
  text-decoration: none;
  position: relative;
  width: 100%;
  padding: 11px 23px;
  z-index: 1;
  display: inline-block;
  text-align: center;
  border-radius: 21px;
  background: rgb(224 195 251);
  box-shadow: 1px 2px 3px 0px #464343, -1px -2px 2px #9b9393;
  transition: all 0.6s ease;
  border: 1px solid #cbcbcb00;
  font-family: "Signika Negative", sans-serif;
}

.navList1 a:hover {
  border: 1px solid #cbcbcb;
  /* background: #ffffff;
  box-shadow: inset 1px -1px 6px 0px #ededed, inset 0px 3px 4px 0px #bbb9b9; */
}

.fa-bars,
.fa-times {
  color: rgb(0, 4, 255);
}
a.active {
  background: #ffffff;
  box-shadow: inset 1px -1px 6px 0px #ededed, inset 0px 3px 4px 0px #bbb9b9;
}
.menuIcon {
  display: none;
}

@media screen and (max-width: 1023px) {
  .NavbarItem {
    position: absolute;
  }
  .navList1 {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 10px;
    height: 105vh;
    position: absolute;
    top: 100%;
    left: -112%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .navList1.active {
    background-color: rgb(153 153 153 / 71%);
    left: -16px;
    opacity: 1;
    transition: all 0.6s ease;
    backdrop-filter: blur(17px);
  }
  .navList1 {
    text-align: center;
    padding: 1.5rem;
    width: 100%;
    gap: 30px;
    flex-direction: column;
    align-items: center;
  }
  .navList1 a {
    width: 40%;
  }
  /* .navList1 a:hover {
    background-color: rgb(223, 3, 3);
    border-radius: 0;
  } */

  .menuIcon {
    display: block;
    position: absolute;
    right: 20px;
    font-size: 1.8rem;
    cursor: pointer;
  }
  .animation {
    display: none;
  }
}
