#contacts {
  font-family: "Signika Negative", sans-serif;
}
.contacts {
  position: relative;
  min-height: 100vh;
  padding: 50px 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* background: linear-gradient(
    to right,
    rgba(29, 29, 29, 0.836),
    rgba(0, 0, 0, 0.74)
  ); */
  /* url(../../Images/contact.jpg); */
  background-size: cover;
}
.icon i.fas {
  color: rgb(0, 0, 0);
  padding: 0px;
}
.contacts .content {
  max-width: 800px;
  text-align: center;
}
.contacts .content h2 {
  font-family: "Signika Negative", sans-serif;
  font-size: 36px;
  font-weight: 500;
  color: rgb(0, 0, 0);
}
.contact-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.contact-container .contactInfo {
  width: 50%;
  display: flex;
  flex-direction: column;
}
.contact-container .contactInfo .box {
  position: relative;
  padding: 20px 0;
  backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
}
.contact-container .contactInfo .box .icon {
  min-width: 60px;
  height: 60px;
  background: rgb(255, 255, 255);
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 22px;
}
.contact-container .contactInfo .box .text {
  display: flex;
  margin-left: 20px;
  font-family: "Signika Negative", sans-serif;
  color: rgb(0, 0, 0);
  flex-direction: column;
  font-weight: 300;
  text-align: start;
}
.contact-container .contactInfo .box .text h3 {
  font-weight: 500;
  color: #000000;
  font-size: 16px;
  font-family: "Signika Negative", sans-serif;
}
.contact-container .contactInfo .box .text p {
  font-weight: 100;
  color: #000000;
  font-size: 16px;
  font-family: "Signika Negative", sans-serif;
}
.contact-container .contactInfo .box .text a {
  font-weight: 100;
  color: #000000;
  text-decoration: none;
  font-size: 16px;
  font-family: "Signika Negative", sans-serif;
}
.contactForm {
  width: 40%;
  padding: 40px;
  background: #fff;
}
.contactForm h2 {
  font-family: "Signika Negative", sans-serif;
  font-size: 30px;
  color: #333;
  font-weight: 500;
}
.contactForm .inputBox {
  position: relative;
  text-align: start;
  width: 100%;
  margin-top: 10px;
}
.contactForm .inputBox input,
.contactForm .inputBox textarea {
  width: 100%;
  padding: 5px 0;
  font-size: 16px;
  margin: 10px 0;
  border: none;
  border-bottom: 2px solid #333;
  outline: none;
  resize: none;
  font-family: "Signika Negative", sans-serif;
}
.contactForm .inputBox span {
  position: absolute;
  left: 0;
  padding: 5px 0;
  font-size: 16px;
  margin: 10px 0;
  pointer-events: none;
  transition: 0.5s;
  color: #666;
  font-family: "Signika Negative", sans-serif;
}
.contactForm .inputBox input:focus ~ span,
.contactForm .inputBox input:valid ~ span,
.contactForm .inputBox textarea:focus ~ span,
.contactForm .inputBox textarea:valid ~ span {
  color: #e91e63;
  font-size: 12px;
  transform: translateY(-20px);
}
.contactForm .inputBox input[type="submit"] {
  width: 100px;
  background: #000000;
  border: none;
  color: #fff;
  cursor: pointer;
  padding: 10px;
  font-size: 18px;
  font-family: "Signika Negative", sans-serif;
}
@media screen and (max-width: 1023px) {
  .contacts {
    padding: 1px 19px;
  }
  .contact-container {
    flex-direction: column;
  }
  .contact-container .contactInfo {
    margin-bottom: 40px;
  }
  .contact-container .contactInfo,
  .contactForm {
    width: 90%;
    place-self: center;
    padding-top: 10px;
  }
}
