#Product {
  height: 100%;
  font-family: "Signika Negative", sans-serif;
}
.product {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 0px;
  justify-content: center;
}

.productItem {
  width: 90%;
  display: grid;
  padding: 30px 0px;
  grid-template-columns: repeat(3, 1fr);
}
.productCardImg {
  height: 300px;
}
.productCardImg img {
  width: 100%;
  height: 90%;
}
.productTitle {
  font-size: 40px;
  color: rgb(58, 58, 58);
  font-weight: 700;
}
.productCard {
  padding: 0 0 10px;
  margin: 20px;
  height: 380px;
  align-items: center;
  font-family: "Poppins", sans-serif;
  flex-direction: column;
  box-shadow: 1px 1px 10px 2px rgb(0 0 0 / 49%);
  transition: all 0.9s ease;
  padding: 20px 10px 0px 10px;
  border-radius: 10px;
}
.productCardInfo h1 {
  font-family: "Signika Negative", sans-serif;
  font-size: 20px;
  padding: 10px 0px;
}
.productCardInfo p {
  font-family: "Signika Negative", sans-serif;
  font-size: 16px;
  text-align: justify;
  padding: 10px 10px 20px 10px;
}
.fa-solid,
.fas {
  font-weight: 900;
  color: #ffffff;
  padding: 20px;
  border-radius: 50%;
}
.fa-code {
  background-color: red;
}
.fa-desktop {
  background-color: rgb(4, 0, 255);
}
.fa-message {
  background-color: rgb(23, 194, 0);
}
/* tablet */
@media (min-width: 768px) and (max-width: 1023px) {
  .productItem {
    width: 90%;
    display: grid;
    padding: 30px 0px;
    grid-template-columns: repeat(2, 1fr);
  }
}
/* Mobile */
@media (max-width: 767px) {
  .productItem {
    width: 90%;
    display: grid;
    padding: 30px 0px;
    grid-template-columns: repeat(1, 1fr);
  }
}
