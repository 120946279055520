.intobg {
  background: black;
}
span.neon {
  font-size: 2.5rem;
  color: white;
  display: block;
  margin: 0;
  padding: 0;
  font-weight: 600;
  animation: animate 3s linear infinite;
  text-transform: uppercase;
}
@keyframes animate {
  0%,
  90% {
    color: #f2f2f2;
    filter: blur(3px);
    text-shadow: 0 0 10px #0099ff;
  }
  0%,
  90% {
    color: #fff;
    filter: blur(0px);
  }
}
.btn {
  font-size: 40px;
  padding: 2.5em;
  margin: 0px;
  text-align: -webkit-center;
  border: 0;
  outline: 0;
  color: #fff;
  background-color: black;
  text-transform: uppercase;
  border-radius: 50%;
  box-shadow: -2px -2px 8px rgb(74 74 74),
    -2px -2px 12px rgba(107, 107, 107, 0.5),
    rgba(66, 66, 66, 0.62) inset 2px 2px 4px rgba(71, 71, 71, 0.32),
    1px 4px 8px rgb(116 124 36 / 62%);
  overflow: hidden;
  cursor: pointer;
  transition: all 0.8s ease;
  animation: animatebtn 1.5s linear;
  transform: scale(0.8);
  width: 450px;
  height: 450px;
}
.btn:hover {
  box-shadow: inset -2px -2px 8px rgb(61, 61, 61),
    inset -2px -2px 10px rgba(138, 136, 136, 0.9),
    inset 2px 2px 4px rgba(148, 147, 147, 0.2),
    inset 2px 2px 8px rgba(88, 88, 88, 0.25);
}
@keyframes animatebtn {
  0% {
    transform: scale(0.1);
  }

  100% {
    transform: scale(0.8);
  }
}
.neon1 {
  display: block;
  width: fit-content;
  position: relative;
  color: #fff;
  text-shadow: 0 0 4px #0099ff;
}

.neon1:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #0099ff;
  z-index: -2;
  opacity: 0.3;
  filter: blur(35px);
}
@keyframes ripple {
  to {
    transform: scale(2.5);
    opacity: 0;
  }
}
/* tablet */
@media (min-width: 768px) and (max-width: 1023px) {
  span.neon {
    font-size: 1.5rem;
  }
}
/* Mobile */
@media (max-width: 767px) {
  span.neon {
    font-size: 1.2rem;
  }
}
