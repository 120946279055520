#Services {
  height: 100%;
  font-family: "Signika Negative", sans-serif;
}
.services {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 0px;
}

.servicesItem {
  width: 90%;
  display: grid;
  padding: 30px 0px;
  grid-template-columns: repeat(3, 1fr);
}
.servicesTitle {
  font-size: 40px;
  color: rgb(58, 58, 58);
  font-weight: 700;
}
.servicesCard {
  padding: 0 0 10px;
  margin: 20px;
  height: 380px;
  align-items: center;
  font-family: "Poppins", sans-serif;
  flex-direction: column;
  border-radius: 18px;
  background: linear-gradient(225deg, #e9fff9, #f8fff9);
  box-shadow: -1px 1px 4px #c8c8c8, 6px -6px 12px #ffffff;
  transition: all 0.9s ease;
  padding: 20px 10px 0px 10px;
  border-radius: 10px;
  background-position: 50%;
}
.servicesCard:hover {
  background: linear-gradient(225deg, #f8fff9, #e9fff9);
  box-shadow: -6px 6px 12px #c8c8c8, 6px -6px 12px #ffffff;
}
.servicesCardImg {
  height: 300px;
}
.servicesCardImg img {
  width: 100%;
  height: 90%;
}
.servicesCardInfo h1 {
  font-family: "Signika Negative", sans-serif;
  font-size: 20px;
  padding: 10px 0px;
}
.servicesCardInfo p {
  font-family: "Signika Negative", sans-serif;
  font-size: 16px;
  text-align: justify;
  padding: 10px 10px 20px 10px;
}
.fa-solid,
.fas {
  font-weight: 900;
  color: #ff0000;
  padding: 20px;
  border-radius: 50%;
}
.fa-code {
  background-color: red;
}
.fa-desktop {
  background-color: rgb(4, 0, 255);
}
.fa-message {
  background-color: rgb(23, 194, 0);
}
/* tablet */
@media (min-width: 768px) and (max-width: 1023px) {
  .servicesItem {
    width: 90%;
    display: grid;
    padding: 30px 0px;
    grid-template-columns: repeat(2, 1fr);
  }
}
/* Mobile */
@media (max-width: 767px) {
  .servicesItem {
    width: 90%;
    display: grid;
    padding: 30px 0px;
    grid-template-columns: repeat(1, 1fr);
  }
}
