#Blog {
  height: 100%;
  display: flex;
}
.blog {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 0px;
  justify-content: center;
}

.blogItem {
  width: 90%;
  display: grid;
  padding: 30px 0px;
  grid-template-columns: repeat(3, 1fr);
}
.blogTitle {
  font-size: 40px;
  color: rgb(58, 58, 58);
  font-weight: 700;
}
.blogCard {
  padding: 0 0 10px;
  margin: 20px;
  align-items: center;
  font-family: "Poppins", sans-serif;
  flex-direction: column;
  box-shadow: 1px 1px 10px 2px rgb(0 0 0 / 49%);
  transition: all 0.9s ease;
  padding: 20px 10px 0px 10px;
  border-radius: 10px;
}
.blogCardInfo h1 {
  font-size: 20px;
  padding: 10px 0px;
}
.blogCardInfo p {
  font-size: 16px;
  text-align: justify;
  padding: 10px 10px 20px 10px;
}
.fa-solid,
.fas {
  font-weight: 900;
  color: #ffffff;
  padding: 20px;
  border-radius: 50%;
}
.fa-code {
  background-color: red;
}
.fa-desktop {
  background-color: rgb(4, 0, 255);
}
.fa-message {
  background-color: rgb(23, 194, 0);
}
